<template>
    <div class="row" id="rowSpinner" v-if="show">
        <div class="col-12 text-center mt-3">
            <span class="spinner spinner-border spinner-card flex-shrink-0" role="status">
                <span class="visually-hidden"></span>
            </span>
        </div>
    </div>
</template>

<script>

export default {
    computed: {
        show() {
            return this.$store.state.api.showSpinner
        }
    }
}

</script>

<style scoped>

#rowSpinner {
    position: fixed;
    top: calc(50% - 2.5rem);
    left: calc(50% - 2.5rem);
    z-index: 9;
}

#rowSpinner .spinner {
    height: 5rem;
    width: 5rem;
}

</style>
